<template>
    <div class="subMenu">
        <div class="title">
            <img :src="titleIconUrl" width="23" />
            <span>{{ titleName }}</span>
        </div>
        <div class="list" v-if="!toggleMenu">
            <div class="list-item" :class="{ active: activeIdx == index }" v-for="(item, index) in subMenuData" :key="index"
                @click="onDetail(item, index)">
                <span></span>
                <div class="name"><em v-if="!hideSerial">{{ item.level }}.{{ index }}</em> {{ item.name }}</div>
            </div>
        </div>
        <div class="list" v-else>
            <div class="levelMenu">
                <el-menu class="el-menu-vertical-demo" :default-active="activeMenu" @select="select" @open="handleOpen"
                    @close="handleClose">
                    <el-submenu v-for="(item) in subMenuData" :index="item.index" :key="item.key">
                        <template slot="title">
                            <span>{{ item.title }}</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item v-for="(subItem) in item.children" :index="subItem.index" :key="subItem.key"
                                @click="onDetail(item, 1)">{{ subItem.title }}</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * subMenuData - 左侧菜单列表数据
 * onDetail - 点击查看数据类型详情
 * titleName - 模块名称
 * titleIconUrl - 模块名称icon路径
 */
export default {
    name: "SubMenu",
    props: ["subMenuData", "titleName", "titleIconUrl", "activeIndex","activeMenu", "hideSerial", "toggleMenu"],
    data() {
        return {
            activeIdx: 0,
        }
    },
    watch: {
        activeIndex(newVal, oldVal) {
            this.activeIdx = this.activeIndex ? this.activeIndex : 0
        }
    },
    mounted() {
        
    },
    methods: {
        onDetail(item, index) {
            this.activeIdx = index
            this.$emit('onDetail', item)
        },
        handleOpen(key, keyPath) {
            this.$emit('handleOpen', keyPath)
        },
        handleClose(key, keyPath) {
            this.$emit('handleClose', keyPath)
        },
        select(key, keyPath) {
            this.$emit('handleSelect', key)
        }
    }
}
</script>

<style lang="less" scoped>
.subMenu {
    .title {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 25px;
        background: url('../../assets/basedata/left_title.png') no-repeat;
        background-size: 100% 70px;

        >img {
            margin-bottom: -2px;
            margin-right: 15px;
        }

        >span {
            color: #fff;
            font-size: 20px;
            font-weight: bold;
        }
    }

    .list {
        background-color: #fff;
        height: 100%;
        padding-bottom: 30px;

        /deep/ .el-menu {
            border-right: none;

            .el-submenu {
                border-bottom: 1px dashed #dce6e8;

                .el-submenu__title {
                    font-size: 16px;
                }
            }

            // .el-menu-item.is-active {
            //     color: #666;
            // }
        }

        >.list-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 62px;
            border-bottom: 1px dashed #dce6e8;
            background-color: #fff;
            cursor: pointer;

            >span {
                width: 11px;
                height: 3px;
                background-color: #fff;
                margin-right: 20px;
            }

            >.name {
                color: #444;

                >em {
                    font-style: normal;
                }
            }

            &:hover {
                background-color: #ebf8fd;

                span {
                    background-color: #00a0e9;
                }

                .name {
                    color: #00a0e9;
                }
            }
        }

        .active {
            background-color: #ebf8fd;

            span {
                background-color: #00a0e9;
            }

            .name {
                color: #00a0e9;
            }
        }
    }
}</style>