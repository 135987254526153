<template>
    <div class="card">
        <div class="cover">
            <img :src="itemData?.url[0].httpAccessUrl" width="100%" height="180" style="pointer-events:none" />
        </div>

        <div class="cardCon">
            <div class="h2" :title="itemData.name">{{itemData.name}}</div>
            <div class="tag">
                <el-tag effect="dark" size="mini"> {{ itemData.typeValue }} </el-tag>
            </div>
            <div class="des" :title="itemData.content">{{itemData.content}}</div>

            <div class="cardfoot">
                <el-button type="primary" plain size="small" @click="toCheckData">
                    <div class="icon icon_bg1"></div>查看数据
                </el-button>
                <el-button type="warning" plain size="small" @click="toSign">
                    <div class="icon icon_bg2"></div>订阅数据
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
/**
 * itemData - 数据信息
 */
export default {
    name: "Card",
    props: ['itemData'],
    data() {
        return {
            infoData: {}
        }
    },
    mounted() {
    },
    methods: {
        // 查看数据
        toCheckData() {
            this.$emit('toCheckData')
        },
        // 订阅数据
        toSign() {
            this.$emit('toSign')
        }
    }
}
</script>

<style lang="less" scoped>
.card {
    > .cover {
        width: 100%;
        height: 180px;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        > img {
            display: block;
            transition: all 0.3s;
            opacity: 0.95;
            &:hover {
                opacity: 1;
                transform: scale(1.1, 1.1);
            }
        }
    }

    > .cardCon {
        background-color: #f5f6fa;
        padding: 20px;
        border-radius: 0 0 8px 8px;
        > .h2 {
            font-size: 18px;
            color: #444;
            margin-bottom: 8px;
            width: 100%;
            -webkit-line-clamp: 1;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        > .tag {
            margin-bottom: 5px;
        }
        > .des {
            height: 66px;
            font-size: 14px;
            color: #818a92;
            line-height: 22px;
            margin-bottom: 8px;
            width: 100%;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        > .cardfoot {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                width: 136px;
                height: 40px;
                .icon {
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    margin-bottom: -3px;
                    margin-right: 6px;
                }
                .icon_bg1 {
                    background: url('../../assets/basedata/icon_check.png')
                        no-repeat;
                    background-size: 15px 15px;
                }
                .icon_bg2 {
                    background: url('../../assets/basedata/icon_dy.png')
                        no-repeat;
                    background-size: 15px 15px;
                }
                &:hover {
                    .icon_bg1 {
                        background: url('../../assets/basedata/icon_check_on.png')
                            no-repeat;
                        background-size: 15px 15px;
                    }
                    .icon_bg2 {
                        background: url('../../assets/basedata/icon_dy_on.png')
                            no-repeat;
                        background-size: 15px 15px;
                    }
                }
                &:focus {
                    .icon_bg1 {
                        background: url('../../assets/basedata/icon_check_on.png')
                            no-repeat;
                        background-size: 15px 15px;
                    }
                    .icon_bg2 {
                        background: url('../../assets/basedata/icon_dy_on.png')
                            no-repeat;
                        background-size: 15px 15px;
                    }
                }
            }
        }
    }
}
</style>